
<template>
  <div>
    <app-header></app-header>

    <div class="login">
      <div class="login-logo text-center">
        <img src="../../assets/images/logo.png" alt="" class="" />
        <p class="text-center">{{ $t('Password Recovery') }}</p>
      </div>
      <div class="text-center">
        <form method="post" @submit.prevent="add()">
          <div class="page">
            <div class="field mb-3">
              <label for="name">{{$t('phone')}}</label>
              <InputText id="name" type="text" v-model="phone" />
            </div>

            <button
              class="btn btn-primary btn-login"
              type="submit"
              :disabled="save"
            >
              {{ $t('Send Code') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../../layouts/header.vue";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      phone: null,
    };
  },

  methods: {
    add() {
      if (this.phone) {
        this.save = true;
        this.$http
          .post(`auth/generateVarifyCodeToChangePassword`, {
            phone: this.phone,
          })
          .then(
            (response) => {
              this.save = false;
              this.$toast.add({
              severity: "success",
              summary: this.$t("Done Successfully"),
              detail: this.$t("Send Code Successfully"),
              life: 3000,
            });
              this.$router.push(
                `/passwordConfirmation?phone=${this.phone}`
              );
            },
            (err) => {
              this.save = false;
              this.$toast.add({
                severity: "error",
                summary: this.$t("There error"),
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$t("There error"),
          detail: this.$t("Please fill in the fields"),
          life: 3000,
        });
      }
    },
  },
  async created() {},
};
</script>
<style></style>
